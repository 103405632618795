<template>
	<div class="turn_page">
		<el-pagination :background="background" :current-page.sync="currentPage" :page-size.sync="pageSize"
			:layout="layout" :page-sizes="pageSizes" :total="total" prev-text="上一页" next-text="下一页"
			@size-change="handleSizeChange" @current-change="handleCurrentChange" />
	</div>
</template>

<script>
	export default {
		name: "pagination",
		props: {
			total: {
				required: true,
				type: Number
			},
			page: {
				type: Number,
				default: 1
			},
			limit: {
				type: Number,
				default: 20
			},
			pageSizes: {
				type: Array,
				default () {
					return [10, 20, 50, 100, 200]
				}
			},
			layout: {
				type: String,
				default: 'total,prev, pager, next, jumper',
			},
			background: {
				type: Boolean,
				default: false,
			},
			hidden: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {

			};
		},
		computed: {
			currentPage: {
				get() {
					return this.page
				},
				set(val) {
					this.$emit('update:page', val)
				}
			},
			pageSize: {
				get() {
					return this.limit
				},
				set(val) {
					this.$emit('update:limit', val)
					this.$emit('update:page', 1)
				}
			}
		},
		methods: {
			handleSizeChange(val) {
				this.$emit('pagination', {
					page: this.currentPage,
					limit: val
				})
			},
			handleCurrentChange(val) {
				this.$emit('pagination', {
					page: val,
					limit: this.pageSize
				})
			}
		}

	}
</script>

<style scoped>
	.turn_page {
		display: flex;
		justify-content: flex-end;
		padding: 28px 34px;
	}
</style>
