function compare(prop,bol=true) {//数组按日期排序
        return function (a, b) {
                return bol ? b[prop] - a[prop] : a[prop] - b[prop] // bol=true降序,bol=true升序
        }
}
// module.exports.compare = compare;

function _navigateTo(url){
	wx.navigateTo({
		url: url,
	})
}
// module.exports._navigateTo = _navigateTo;

function _date(time,str_format='Y-m-d H:i:s') {  
	var date = new Date();  
	if(typeof(time)!='undefined' && time){
		date.setTime(time*1000);  
	}
	var y = date.getFullYear();
	var m = (date.getMonth() + 1);
	m = m < 10 ? '0'+m : m;
	var d = date.getDate();
	d = d < 10 ? '0'+d : d;
	var h = date.getHours();
	h = h < 10 ? '0'+h : h;
	var i = date.getMinutes();
	i = i < 10 ? '0'+i : i;
	var s = date.getSeconds();
	s = s < 10 ? '0'+s : s;
	str_format = str_format.replace('Y',y);
	str_format = str_format.replace('m',m);
	str_format = str_format.replace('d',d);
	str_format = str_format.replace('H',h);
	str_format = str_format.replace('i',i);
	str_format = str_format.replace('s',s);
	return str_format;
}  
// module.exports._date = _date;

function timestamp(_date){
	if(typeof(_date)!='undefined'){
		var timestamp = Date.parse(new Date(_date));
	}
	else{
		var timestamp = Date.parse(new Date());
	}
	return timestamp/1000;
}
// module.exports.timestamp = timestamp;

var _verify_mobile = function(mobile){
	if(mobile.match(/^(13|14|15|16|17|18|16|19)[\d]{9}$/)){
		return true;
	}
	return false;
}
// module.exports._verify_mobile = _verify_mobile;

var echo_price = function(float_price){
	if(float_price==parseInt(float_price)){
		return 	parseInt(float_price);
	}

	return parseFloat(float_price).toFixed(2);
}
// module.exports.echo_price = echo_price;

function sub_string(str, len, hasDot)  
{  
    var newLength = 0;  
    var newStr = "";  
    var chineseRegex = /[^\x00-\xff]/g;  
    var singleChar = "";  
    var strLength = str.replace(chineseRegex,"**").length;  
    for(var i = 0;i < strLength;i++)  
    {  
        singleChar = str.charAt(i).toString();  
        if(singleChar.match(chineseRegex) != null)  
        {  
            newLength += 2;  
        }      
        else  
        {  
            newLength++;  
        }  
        if(newLength > len)  
        {  
            break;  
        }  
        newStr += singleChar;  
    }  

    if(hasDot && strLength > len)  
    {  
        newStr += "...";  
    }  
    return newStr;  
}
// module.exports.sub_string = sub_string;

/*根据大图获取小图*/
var resize_img = function(url,w,h){
	//console.log(url);
		if(url.split('.gif').length>1)  return url;
		if(!url || (!w && !h)) return false;
		/*if(){
			return url;
		}
		*/
		var temp = url.substr(url.lastIndexOf('.'),4);
		
		if(url.lastIndexOf('_')<10){
			url = url.substr(0,url.lastIndexOf('.'));
		}
		else{
			url = url.substr(0,url.lastIndexOf('_'));
		}
		
		var size = '';
		if(w>0&&h>0) size  = w+'x'+h;
		else if(w>0) size  = w;
		else size = '0x'+h;
		return url+'_'+size+temp;
	}
// module.exports.resize_img = resize_img;

/* 返回数组的下标和数据 */
var for_return_key_data = function(arr, condition1, condition2){
	var return_data = [];
	for(var i = 0; i < arr.length; i++){
		var ci = arr[i];
		if(ci[condition1] == condition2){
			return_data['key'] = i;
			return_data['data'] = ci;
		}
	}
	return return_data;
}
// module.exports.for_return_key_data = for_return_key_data;

/* 生成url */
/* var make_request_data = function(param){
	var paramStr = '';
	for (var i in param) {  
		var k = key == null ? i : key + (param instanceof Array ? '[' + i + ']' : '.' + i);  
		paramStr += urlEncode(param[i], k, encode);  
    }  
} */
var url_encode = function (param, encode, key) {  
	if(param==null) return '';  
	var paramStr = '';  
	var t = typeof (param);  
	if (t == 'string' || t == 'number' || t == 'boolean') {
		paramStr += '&' + key + '=' + ((encode==null||encode) ? encodeURIComponent(param) : param);  
	} else {  
		for (var i in param) { 
			var k = key == null ? i : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
			paramStr += url_encode(param[i], encode, k);
		}
	}
	return paramStr;  
}
// module.exports.url_encode = url_encode;



/**
 * /校验身份证是否合法
 * @param  {[type]} idcard [description]
 * @return {[type]}        [description]
 */
var check_id_card = function (idcard) {
	var Errors = new Array(
		"OK",
		"请输入18位身份证号!",
		"身份证号码出生日期超出范围或含有非法字符!",
		"身份证号码校验错误!",
		"身份证地区非法!"
	);
	var area = {
		11: "北京",
		12: "天津",
		13: "河北",
		14: "山西",
		15: "内蒙古",
		21: "辽宁",
		22: "吉林",
		23: "黑龙江",
		31: "上海",
		32: "江苏",
		33: "浙江",
		34: "安徽",
		35: "福建",
		36: "江西",
		37: "山东",
		41: "河南",
		42: "湖北",
		43: "湖南",
		44: "广东",
		45: "广西",
		46: "海南",
		50: "重庆",
		51: "四川",
		52: "贵州",
		53: "云南",
		54: "西藏",
		61: "陕西",
		62: "甘肃",
		63: "青海",
		64: "宁夏",
		65: "新疆",
		71: "台湾",
		81: "香港",
		82: "澳门",
		91: "国外"
	}
    	var idcard, Y, JYM;
    	var S, M;
    	var idcard_array = new Array();
    	idcard_array = idcard.split("");
	
    	//地区检验 
    	if (area[parseInt(idcard.substr(0, 2))] == null) return Errors[4];
    	//身份号码位数及格式检验 
	switch (idcard.length) {
	        case 18:
		        if (parseInt(idcard.substr(6, 4)) % 4 == 0 || (parseInt(idcard.substr(6, 4)) % 100 == 0 && parseInt(idcard.substr(6, 4)) % 4 == 0)) {
		                var ereg = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}[0-9Xx]$/; //闰年出生日期的合法性正则表达式 
		        } else {
		                var ereg = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}[0-9Xx]$/; //平年出生日期的合法性正则表达式 
		        }
		        if (ereg.test(idcard)) {//测试出生日期的合法性 
		                //计算校验位 
		                S = (parseInt(idcard_array[0]) + parseInt(idcard_array[10])) * 7
		                + (parseInt(idcard_array[1]) + parseInt(idcard_array[11])) * 9
		                + (parseInt(idcard_array[2]) + parseInt(idcard_array[12])) * 10
		                + (parseInt(idcard_array[3]) + parseInt(idcard_array[13])) * 5
		                + (parseInt(idcard_array[4]) + parseInt(idcard_array[14])) * 8
		                + (parseInt(idcard_array[5]) + parseInt(idcard_array[15])) * 4
		                + (parseInt(idcard_array[6]) + parseInt(idcard_array[16])) * 2
		                + parseInt(idcard_array[7]) * 1
		                + parseInt(idcard_array[8]) * 6
		                + parseInt(idcard_array[9]) * 3;
		                Y = S % 11;
		                M = "F";
		                JYM = "10X98765432";
		                M = JYM.substr(Y, 1); //判断校验位 
		                if (M == idcard_array[17]) return Errors[0]; //检测ID的校验位 
		                else return Errors[3];
		        }
		        else return Errors[2];
		        break;
	        default:
	            	return Errors[1];
	            	break;
	}
}
// module.exports.check_id_card = check_id_card;

function format_array_val_to_key(arr, key){
	var arr_return = new Array();
	for(var i=0; i<arr.length; i++){
		arr_return[arr[i][key]] = arr[i];
	}
	return arr_return;
}
// module.exports.format_array_val_to_key = format_array_val_to_key;

function bMapTransQQMap(lat, lng){
	var x_pi = 3.14159265358979324 * 3000.0 / 180.0;
	var x = lng - 0.0065;
	var y = lat - 0.006;
	var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
	var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
	var lngs = z * Math.cos(theta);
	var lats = z * Math.sin(theta);
	return {lat: lats, lng: lngs};
}
// module.exports.bMapTransQQMap = bMapTransQQMap;

function is_chn(str){ 
	var reg=/^[\u4E00-\u9FA5]+$/; 
	if(!reg.test(str)){ 
		return false; 
	} 
	return true; 
}
// module.exports.is_chn = is_chn;

export default {
	compare,
	_navigateTo,
	_date,
	timestamp,
	_verify_mobile,
	echo_price,
	sub_string,
	resize_img,
	for_return_key_data,
	url_encode,
	check_id_card,
	format_array_val_to_key,
	bMapTransQQMap,
	is_chn,
}